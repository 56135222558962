import { isMobile } from '../utils/index'
export default {
  address: '新竹縣竹東鎮北興路二段220號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.5640792278596!2d121.0905563!3d24.741841700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346847c0ff9d9d9f%3A0xfde0c21acf6074e1!2z6Iez5ZaE6auY56ysLeaOpeW-heacg-mkqO-8iOiIiOWQiOWKm-W7uuevieWcmOmaii3llJDoqqDlu7roqK3vvIk!5e0!3m2!1szh-TW!2stw!4v1658989610500!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/wg3ETypf1BikQV16A',
  phone: '03-595-7999',
  fbLink: 'https://www.facebook.com/109284968192482/',
  fbMessage: 'https://m.me/109284968192482',
  caseName: '至善高第',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '興合力建築團隊-唐誠建設股份有限公司',],
    ['建築設計', '徐丹和建築師事務所',],
    ['外觀設計', '原典設計股份有限公司',],
    ['公設園藝', '和境設計工程有限公司',],
    ['建照號碼', '(111)府建字第00146號',],
    ['樓層規劃', '地上14層/地下3層',],
    ['戶數規劃', '124戶/127車',],
    ['廣告代銷', '新理想行銷團隊-新理想廣告股份有限公司',],
    ['建案位置', '新竹縣竹東鎮至善段40~43等4筆地號',],
  ],

  gtmCode: ['MH44SN4'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
    room_type: ['套房','兩房','三房'],//房型
  }
}
