export default [
  /* */
  { name: '至善高第', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '地段發展', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '爭藏優勢', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '交通動線', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '基地優勢', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '公設規劃', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '格局規劃', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '建材設備', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
 
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
]